import {
    Bar,
    Grid,
    Histogram,
    onMouseMove,
    onTouchMove,
    Scale,
} from 'd3-charts-react';
import React, { useMemo, useState } from 'react';
import { Axis, axisPropsFromTickScale, LEFT } from 'react-d3-axis';
import ReactResizeDetector from 'react-resize-detector';

import styles from './PowerHistogram.module.scss';
import PowerHistogramStatistics from './PowerHistogramStatistics';

import { FtpDisplay } from '../thresholdDisplay';

import {
    extractAccumulate,
    findZoneIndex,
    formatPower,
    mutateScaleNice,
} from '../../chartHelpers';
import BikeComponentLink from '../../bikeComponentLink';

import Translate from '../../../../components/translate';
import { RED_LIGHT, SRAM_200, ZONE_COLORS_POWER } from '../../../../constants';
import { useNexus } from '../../../../providers/nexus/NexusContext';
import { useUnits } from '../../../../providers/units/UnitsContext';
import ExpandChart from '../../../../views/expandChart';
import { ComponentSummary } from '../../../../providers';

const generateBinThreshold = (min: number, max: number, step: number) => {
    if (!Number.isFinite(step)) {
        return null;
    }

    const thresholdArray = [];
    for (let i = min; i < max; i += step) {
        thresholdArray.push(i);
    }

    return thresholdArray;
};

interface PowerHistogramProps {
    disableEdit?: boolean;
    disableOwnerFeatures?: boolean;
    powerComponent: ComponentSummary;
    powerZones: {min: number, max: number}[];
}

const PowerHistogram = ({
    disableEdit = false,
    disableOwnerFeatures = false,
    powerComponent,
    powerZones,
}: PowerHistogramProps) => {
    const { nexusUserProfile } = useNexus();
    const { formatDuration } = useUnits();
    // cannot set interface due to array values having x0, x1
    const [bin, setBin] = useState<any>(null);
    const [isLastBin, setIsLastBin] = useState(false);
    const ftp = (nexusUserProfile && Number.isFinite(nexusUserProfile.ftp)) ? nexusUserProfile.ftp : 200;

    const {
        binWidth,
        max,
        thresholds,
        xExtractor,
    } = useMemo(() => {
        if (!powerComponent) {
            return {
                binWidth: 0,
                max: null,
                thresholds: null,
                xExtractor: null,
            };
        }

        let newMax = Math.min(
            (ftp * 2),
            powerComponent.data.max_power,
        );

        const newBinWidth = Math.max(
            Math.round(ftp * 0.05),
            1,
        );
        newMax = newMax - (newMax % newBinWidth) + newBinWidth;

        const newThresholds = generateBinThreshold(
            0,
            newMax,
            newBinWidth,
        );

        return {
            binWidth: newBinWidth,
            max: newMax,
            thresholds: newThresholds,
            xExtractor: (power: number) => ((max && power > max) ? max : power),
        };
    }, [powerComponent, ftp]);

    if (!powerComponent) {
        return null;
    }

    if (!powerComponent.data.power.length) {
        return (
            <div className={styles.noDataNote}>
                <Translate>NO_DATA_AVAILABLE_NOTES</Translate>
            </div>
        );
    }

    return (
        <ExpandChart title="POWER_DISTRIBUTION">
            <div className={styles.container}>
                <PowerHistogramStatistics bin={bin} isLastBin={isLastBin} />

                <div className={styles.yAxisLabel}>
                    <Translate>DURATION</Translate>
                </div>
                <Histogram
                    accumulateExtractor={extractAccumulate}
                    extractor={xExtractor}
                    binMutator={(generator: {
                        thresholds: (arg0: any[] | null) => any;
                    }) => generator.thresholds(thresholds)}
                    data={powerComponent.data.power}
                    max={max}
                    min={0}
                >
                    {({ bins }: any) => (
                        <ReactResizeDetector handleWidth handleHeight>
                            {({ width, height }) => (
                                <Scale
                                    height={height}
                                    width={width}
                                    xMax={max}
                                    xMin={0}
                                    yMax={Math.max(...bins.map(({ total } : any) => total)) * 1.05}
                                    yMin={0}
                                    yMutator={mutateScaleNice}
                                >
                                    {(scale: {
                                        x: (arg0: number) => number;
                                        y: (arg0: number) => number;
                                    }) => {
                                        const circleRadius = Math.min(
                                            scale.x(Number(binWidth) / 2),
                                            (
                                                scale.y(Math.max(...bins.map(({ total }: any) => total)))
                                                / 2
                                                - 2
                                            ),
                                        );

                                        const xAxisProps = axisPropsFromTickScale(scale.x, 3);
                                        const yAxisProps = axisPropsFromTickScale(scale.y, 3);

                                        return (
                                            <div className={styles.chartContainer}>
                                                <svg
                                                    height={height}
                                                    className={styles.chart}
                                                    onMouseMove={(event) => {
                                                        const hoveredPower = onMouseMove(event, scale);

                                                        if (hoveredPower === null) {
                                                            setBin(null);
                                                            setIsLastBin(false);
                                                            return;
                                                        }

                                                        const binIndex = bins.findIndex((
                                                            { data }: any, index: number,
                                                        ) => (
                                                            index === bins.length - 1
                                                            || hoveredPower <= data.x1
                                                        ));

                                                        setBin(bins[binIndex]);
                                                        setIsLastBin(binIndex === (bins.length - 1));
                                                    }}
                                                    onMouseLeave={() => {
                                                        setBin(null);
                                                        setIsLastBin(false);
                                                    }}
                                                    onTouchMove={(event) => {
                                                        const hoveredPower = onTouchMove(event, scale);

                                                        if (hoveredPower === null) {
                                                            setBin(null);
                                                            setIsLastBin(false);
                                                            return;
                                                        }

                                                        const binIndex = bins.findIndex((
                                                            { data }: any, index: number,
                                                        ) => (
                                                            index === bins.length - 1
                                                            || hoveredPower <= data.x1
                                                        ));

                                                        setBin(bins[binIndex]);
                                                        setIsLastBin(binIndex === bins.length - 1);
                                                    }}
                                                    width={width}
                                                >
                                                    <Grid
                                                        hideXTicks
                                                        scale={scale}
                                                        xTicks={xAxisProps.values}
                                                        yLineStyle={{ stroke: SRAM_200 }}
                                                        yTicks={yAxisProps.values}
                                                    />
                                                    {bins.map((inBin: any,
                                                        index: React.Key | null | undefined) => (
                                                        <Bar
                                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                                            {...inBin}
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={index}
                                                            scale={scale}
                                                            style={{
                                                                fill: disableOwnerFeatures
                                                                    ? RED_LIGHT
                                                                    : ZONE_COLORS_POWER[
                                                                        Math.max(
                                                                            findZoneIndex(
                                                                                (inBin.data.x0 + inBin.data.x1) / 2,
                                                                                powerZones,
                                                                            ),
                                                                            0,
                                                                        )
                                                                    ],
                                                            }}
                                                        />
                                                    ))}
                                                    {bin && (
                                                        <circle
                                                            style={{
                                                                fill: disableOwnerFeatures
                                                                    ? RED_LIGHT
                                                                    : ZONE_COLORS_POWER[
                                                                        Math.max(
                                                                            findZoneIndex(
                                                                                (bin.data.x0 + bin.data.x1) / 2,
                                                                                powerZones,
                                                                            ),
                                                                            0,
                                                                        )
                                                                    ],
                                                            }}
                                                            cx={scale.x((bin.data.x0 + bin.data.x1) / 2)}
                                                            cy={(scale.y(bin.total)
                                                                - circleRadius
                                                                - 2
                                                            )}
                                                            r={circleRadius}
                                                        />
                                                    )}
                                                    <g className={styles.axis}>
                                                        <Axis
                                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                                            {...yAxisProps}
                                                            format={formatDuration}
                                                            style={{ orient: LEFT }}
                                                        />
                                                    </g>
                                                    <g
                                                        className={styles.axis}
                                                        style={{ transform: `translateY(${height}px)` }}
                                                    >
                                                        <Axis
                                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                                            {...xAxisProps}
                                                            format={formatPower}
                                                        />
                                                    </g>
                                                </svg>
                                            </div>
                                        );
                                    }}
                                </Scale>
                            )}
                        </ReactResizeDetector>
                    )}
                </Histogram>
                <div className={styles.xAxisLabel}>
                    <Translate>UNITS_WATTS</Translate>
                </div>
                {!disableEdit && (
                    <div className={styles.footer}>
                        <FtpDisplay />
                        <BikeComponentLink componentSummary={powerComponent} />
                    </div>
                )}
            </div>
        </ExpandChart>
    );
};

export default PowerHistogram;
