import { scaleTime } from 'd3';
import {
    Grid,
    Line,
    lineBisector,
    onMouseMove,
    onTouchMove,
    Scale,
    VerticalLine,
} from 'd3-charts-react';
import { Axis, axisPropsFromTickScale, LEFT } from 'react-d3-axis';
import ReactResizeDetector from 'react-resize-detector';

import PressureAlarmLine from './pressureAlarmLine';
import styles from './TyreLineChart.module.scss';

import AltitudeChart from '../../../altitudeChart';
import BikeComponentLink from '../../../bikeComponentLink';
import { extractTime, extractValue, mutateScaleNice } from '../../../chartHelpers';
import TemperatureChart from '../../../temperatureChart';

import Translate from '../../../../../components/translate';
import { RED_LIGHT, SRAM_200, toLocaleString } from '../../../../../constants';
import { useSetState } from '../../../../../hooks';
import { ComponentSummary, useUnits } from '../../../../../providers';

interface TyreLineChartProps {
    colour?: string,
    disableEdit: boolean,
    gpsComponent: ComponentSummary,
    onHoveredValues?: (newPressure: number) => void,
    showAltitude: boolean,
    setSync: (value: number | null) => void,
    sync: any,
    title: string,
    tyreWiz: any,
}

const DEFAULT_STATE = { altitude: 0, pressure: 0, temperature: 0 };

function TyreLineChart({
    colour = RED_LIGHT,
    disableEdit = true,
    gpsComponent,
    onHoveredValues = () => { /* do nothing */ },
    showAltitude = false,
    setSync = () => { /* do nothing */ },
    sync = null,
    title = 'CHART',
    tyreWiz = null,
}: TyreLineChartProps) {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const units = useUnits();
    const { altitude, pressure, temperature } = state;

    const formatPressure = (newPressure: number) => {
        if (!Number.isFinite(newPressure)) return null;

        return toLocaleString(units.formatPressure(units.convertPressureFromSI(newPressure)));
    };

    const pressureExtractor = (...params: any) => {
        const values = lineBisector(...params);
        const newPressure = values ? values[1] : 0;

        onHoveredValues(newPressure);

        setState({ pressure: newPressure });
    };

    const renderAltitude = () => {
        if (!Number.isFinite(altitude)) return null;

        return (
            <>
                {toLocaleString(altitude)}
                &nbsp;
                <Translate>{units.getLabelAltitude().shorthand}</Translate>
            </>
        );
    };

    const renderChart = (scale: any) => {
        const xAxisProps = axisPropsFromTickScale(scale.x, 3);
        const yAxisProps = axisPropsFromTickScale(scale.y, 3);

        const width = scale.x.range()[1];
        const height = scale.y.range()[0];

        return (
            <div className={styles.chartContainer}>
                <svg
                    className={styles.chart}
                    height={height}
                    onMouseLeave={() => {
                        setState({ altitude: 0, pressure: 0, temperature: 0 });
                        setSync(null);
                    }}
                    onMouseMove={(event) => {
                        const hoveredTimeStamp = onMouseMove(event, scale);

                        if (hoveredTimeStamp) {
                            setSync(hoveredTimeStamp);
                        }
                    }}
                    onTouchMove={(event) => {
                        const hoveredTimeStamp = onTouchMove(event, scale);

                        if (hoveredTimeStamp) {
                            setSync(hoveredTimeStamp);
                        }
                    }}
                    width={width}
                >
                    {(showAltitude
                        ? (
                            <AltitudeChart
                                gpsComponent={gpsComponent}
                                height={height}
                                onAltitudeChange={(value) => setState({ altitude: value })}
                                sync={sync}
                                width={width}
                            />
                        )
                        : (
                            <TemperatureChart
                                gpsComponent={gpsComponent}
                                height={height}
                                onTemperatureChange={(value) => setState({ temperature: value })}
                                sync={sync}
                                width={width}
                            />
                        )
                    )}
                    <Grid
                        hideXTicks
                        scale={scale}
                        yLineStyle={{ stroke: SRAM_200 }}
                        yTicks={yAxisProps.values}
                    />
                    <Line
                        data={tyreWiz.data.pressure}
                        scale={scale}
                        style={{ stroke: colour }}
                        xExtractor={(...params: any) => extractTime(tyreWiz, ...params)}
                        yExtractor={extractValue}
                        valueExtractor={pressureExtractor}
                        sync={sync}
                    />
                    <PressureAlarmLine pressure={tyreWiz.data.high_alarm_value} scale={scale} />
                    <PressureAlarmLine pressure={tyreWiz.data.low_alarm_value} scale={scale} strokeDasharray={30} />
                    <VerticalLine scale={scale} sync={sync} />
                    {sync && (
                        <circle
                            className={styles.indicator}
                            cx={scale.x(sync)}
                            cy={Math.min(scale.y(pressure), 400)}
                            r={7}
                        />
                    )}
                    <g className={styles.axis}>
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...yAxisProps}
                            format={formatPressure}
                            style={{ orient: LEFT }}
                        />
                    </g>
                    <g
                        className={styles.axis}
                        style={{ transform: `translateY(${height}px)` }}
                    >
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...xAxisProps}
                            format={units.formatTime}
                        />
                    </g>
                </svg>
                <div className={styles.mobileTimeScale}>
                    <div className={styles.startTime}>
                        {units.formatTime(tyreWiz.adjustedStartTs * 1000)}
                    </div>
                    <div className={styles.endTime}>
                        {units.formatTime(tyreWiz.adjustedEndTs * 1000)}
                    </div>
                </div>
            </div>
        );
    };

    const renderScale = () => {
        const yMax = (tyreWiz.data.max_pressure + (tyreWiz.data.max_pressure * 0.05)) * 1.05;

        const yMin = Math.max(
            ((tyreWiz.data.min_pressure - (tyreWiz.data.min_pressure * 0.05)) * 0.95),
            0,
        );

        return (
            <ReactResizeDetector handleWidth handleHeight>
                {({ width, height }) => (
                    <Scale
                        height={height}
                        width={width}
                        xMax={tyreWiz.adjustedEndTs * 1000}
                        xMin={tyreWiz.adjustedStartTs * 1000}
                        xScale={scaleTime}
                        yMax={yMax}
                        yMin={yMin}
                        yMutator={mutateScaleNice}
                    >
                        {(scale: any) => renderChart(scale)}
                    </Scale>
                )}
            </ReactResizeDetector>
        );
    };

    const renderTemperature = () => {
        if (!Number.isFinite(temperature)) return null;

        // to compensate for off-set exponent conversion
        const convertedTemperature = sync ? temperature : 0;

        return (
            <>
                {toLocaleString(convertedTemperature)}
                &nbsp;
                <Translate>{units.getLabelTemperature().shorthand}</Translate>
            </>
        );
    };

    if (!tyreWiz) return null;

    const pressureUnits = units.getLabelPressure();

    return (
        <div>
            <div className={styles.title}>
                <Translate>{title}</Translate>
            </div>
            <div className={styles.valueContainer}>
                <div className={styles.value}>
                    {formatPressure(pressure)}
                    &nbsp;
                    <Translate>{pressureUnits.shorthand}</Translate>
                </div>
                <div className={styles.value}>
                    {(showAltitude ? renderAltitude() : renderTemperature())}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.yAxisLabel}>
                    <Translate>{pressureUnits.shorthand}</Translate>
                </div>
                {renderScale()}
                <div className={styles.xAxisLabel}>
                    <Translate>TIME</Translate>
                </div>
            </div>
            {!disableEdit && <BikeComponentLink componentSummary={tyreWiz} />}
        </div>
    );
}

export default TyreLineChart;
