import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';

import styles from './DateRangePicker.module.scss';

import Translate from '../translate';

import { SRAM_RED } from '../../constants';

const materialTheme = createTheme({
    palette: { primary: { main: SRAM_RED } },
});

// @ts-ignore
const USER_LOCALE = navigator.language || navigator.userLanguage;
const LOCALE_DATE_FORMATE = USER_LOCALE
    ? moment.localeData(USER_LOCALE).longDateFormat('L')
    : 'DD/MM/YYYY';

interface DateRangePickerProps {
    className?: string;
    end: Date | moment.Moment | null;
    hideClear?: boolean;
    onSave: (object: Record<string, moment.Moment | null>) => void;
    start: Date | moment.Moment | null;
}

const DateRangePicker = ({
    className = '',
    end = new Date(),
    hideClear = false,
    onSave = () => {},
    start = null,
}: DateRangePickerProps) => {
    const [stagedEnd, setStagedEnd] = useState<moment.Moment | null>(end ? moment(end) : null);
    const [stagedStart, setStagedStart] = useState<moment.Moment | null>(start ? moment(start) : null);
    const [endOpen, setEndOpen] = useState(false);
    const [startOpen, setStartOpen] = useState(false);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={materialTheme}>
                <div className={styles.container}>
                    <div className={styles.row}>
                        <div className={`${styles.label} ${className}`}>
                            <Translate>FROM</Translate>
                        </div>
                        <DatePicker
                            open={startOpen}
                            disableFuture
                            format={LOCALE_DATE_FORMATE}
                            onAccept={(date) => onSave({ from: date })}
                            onChange={(newDate) => {
                                setStagedStart(newDate)
                            }}
                            onClose={() => {
                                setStartOpen(!startOpen)
                            }}
                            value={stagedStart}
                            sx={{
                                '.MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    borderBottom: '1px solid #000',
                                    borderRadius: '0px'
                                },
                                '.MuiOutlinedInput-root': {
                                    border: 'none',
                                },
                                '.MuiOutlinedInput-input': {
                                    padding: '8px',
                                    paddingInline: '0px',
                                },
                             }}
                             slotProps={{
                                textField: {
                                    onClick: () => setStartOpen(true),
                                },
                                openPickerIcon: {
                                    onClick: () => setStartOpen(true),
                                }
                                
                            }}

                        />
                    </div>
                    <div className={styles.row}>
                        <div className={`${styles.label} ${className}`}>
                            <Translate>TO</Translate>
                        </div>
                        <DatePicker
                            open={endOpen}
                            disableFuture
                            format={LOCALE_DATE_FORMATE}
                            onAccept={(date) => onSave({ to: date })}
                            onChange={(newDate) => {
                                setStagedEnd(newDate)
                            }}
                            onClose={() => {
                                setEndOpen(!endOpen)
                            }}
                            value={stagedEnd}
                            sx={{
                                '.MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    borderBottom: '1px solid #000',
                                    borderRadius: '0px'
                                },
                                '.MuiOutlinedInput-root': {
                                    border: 'none',
                                },
                                '.MuiOutlinedInput-input': {
                                    padding: '8px',
                                    paddingInline: '0px',
                                },
                             }}
                            slotProps={{
                                textField: {
                                    onClick: () => setEndOpen(true),
                                },
                                openPickerIcon: {
                                    onClick: () => setEndOpen(true),
                                }
                            }}
                            />
                    </div>
                    {!hideClear && (
                        <div style={{ textAlign: 'right' }}>
                            <button
                                className={`${styles.clearButton} ${
                                    end || start ? styles.active : ''
                                }`}
                                onClick={() => {
                                    setStagedEnd(null);
                                    setStagedStart(null);
                                    onSave({ from: null, to: null });
                                }}
                                type="button"
                            >
                                <Translate>CLEAR</Translate>
                            </button>
                        </div>
                    )}
                </div>
            </ThemeProvider>
        </LocalizationProvider>
    );
};

export default DateRangePicker;
