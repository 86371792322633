import { useEffect, useRef } from 'react';

import styles from './AssignBikeModal.module.scss';

import Button from '../../../../../components/button';
import Modal from '../../../../../components/modal';
import Spinner from '../../../../../components/spinner';
import TranslateOption from '../../../../../components/translateOption';
import Translate, { useTranslation } from '../../../../../components/translate';
import { AXS_TEXT_GREY, makeCancellable, RequestType } from '../../../../../constants';
import { useSetState } from '../../../../../hooks';
import { Activity, Bike, useBikes } from '../../../../../providers';
import BikeCard from '../../../../../views/bikeCard';
import BikeForm from '../../../../../views/bikeForm';
import ErrorModal from '../../../../../views/errorModal';
import { ExtendedBike } from '../../../../../providers/bikes/types';

const DEFAULT_STATE = {
    assignedBike: null,
    error: null,
    imagePreview: null,
    isFetching: false,
    isUpdating: false,
    showNewBikeForm: false,
};

interface AssignBikeModalProps {
    activity: Activity;
    closeActivityDropdown?: () => void;
    onSave?: (bike: ExtendedBike) => void;
    updateActivity?: (data: { bike_uuid: string }) => void;
    onCancel: () => void;
    open?: boolean;
}


const AssignBikeModal = ({
    activity,
    closeActivityDropdown = () => {},
    onSave = () => {},
    updateActivity = () => {},
    onCancel,
    open = false,
}: AssignBikeModalProps) => {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const bikes = useBikes();
    const translate = useTranslation();
    const {
        assignedBike,
        error,
        imagePreview,
        isFetching,
        isUpdating,
        showNewBikeForm,
    } = state;

    const addBikeRequest = useRef<RequestType | null>(null);
    const fetchBikeRequest = useRef<any>(null);
    const updateActivityRequests = useRef<any>(new Set());
    const assignBikeToActivityRequests = useRef<any>(new Set());

    async function fetchBike() {
        setState({ isFetching: true });

        if (activity.bike_uuid) {
            fetchBikeRequest.current = makeCancellable(bikes.fetchBike(activity.bike_uuid, true));
        }

        try {
            const bike = await fetchBikeRequest.current.promise;

            fetchBikeRequest.current = null;

            if (!bike) {
                setState({ isFetching: false });

                return;
            }

            setState({ assignedBike: bike, isFetching: false });
        } catch (err: any) {
            if (!err.isCancelled) {
                fetchBikeRequest.current = null;
                setState({ isFetching: false });
            }
        }
    }

    async function assignBikeToActivity() {
        if (!assignedBike || assignedBike.id === activity.bike_uuid) return;

        const fetchRequest = makeCancellable(updateActivity({ bike_uuid: assignedBike.uuid }));

        updateActivityRequests.current.add(fetchRequest);

        try {
            const updatedActivity = await fetchRequest.promise;
            updateActivityRequests.current.delete(fetchRequest);

            if (!updatedActivity) {
                setState({ error: new Error('Error Assigning bike to activity') });

                return;
            }

            onSave(assignedBike);
        } catch (err: any) {
            if (!err.isCancelled) {
                updateActivityRequests.current.delete(fetchRequest);
                setState({ error: new Error('Error Assigning bike to activity') });
            }
        }

        closeActivityDropdown();
    }

    async function saveBike(bike: Bike) {
        if (!bike.name || !bike.name.length) return;

        setState({ isUpdating: true });

        addBikeRequest.current = makeCancellable(bikes.addBike(bike));

        try {
            const newBike = await addBikeRequest.current.promise;
            addBikeRequest.current = null;

            if (newBike) {
                setState({ assignedBike: newBike });
            } else {
                setState({ error: new Error('BIKE_NEW_ERROR'), isUpdating: false });
            }
        } catch (err: any) {
            if (!err.isCancelled) {
                addBikeRequest.current = null;
                setState({ error: new Error('BIKE_NEW_ERROR'), isUpdating: false });
            }
        }
    }

    function toggleNewBikeForm() {
        setState({ showNewBikeForm: !showNewBikeForm });
    }

    useEffect(() => {
        if (showNewBikeForm) {
            setState({ showNewBikeForm: false });
            assignBikeToActivity();
        }

        return () => {
            if (addBikeRequest.current) {
                addBikeRequest.current.cancel();
            }

            if (fetchBikeRequest.current) {
                fetchBikeRequest.current.cancel();
            }

            updateActivityRequests.current.forEach((request: { cancel: () => any; }) => request.cancel());
            updateActivityRequests.current.clear();

            assignBikeToActivityRequests.current.forEach((request: { cancel: () => any; }) => request.cancel());
            assignBikeToActivityRequests.current.clear();
        };
    }, [assignedBike]);

    function renderNewBikeForm() {
        if (!showNewBikeForm) return null;

        return (
            <div className={styles.formContainer} style={{ width: '100%' }}>
                <BikeCard
                    bike={{ name: translate('BIKE_NEW_PROFILE') }}
                    image={imagePreview}
                />
                <BikeForm
                    nameIsRequired
                    onImageSelected={(newImagePreview) => setState({ imagePreview: newImagePreview })}
                    onSubmit={(bike) => saveBike(bike)}
                >
                    <div className={`${styles.buttonsContainer} ${styles.bikeButtonsContainer}`}>
                        <Button
                            className={styles.buttonEditBike}
                            color={AXS_TEXT_GREY}
                            inverse
                            onClick={() => toggleNewBikeForm()}
                        >
                            <Translate>CANCEL</Translate>
                        </Button>
                        <Button className={styles.buttonEditBike} type="submit">
                            <Translate>SAVE</Translate>
                        </Button>
                    </div>
                </BikeForm>
            </div>
        );
    }

    function renderAssignBikeForm() {
        if (showNewBikeForm) return null;

        return (
            <>
                <div className={styles.assignBikeContainer}>
                    <div className={styles.title}>
                        <Translate>MY_BIKES</Translate>
                    </div>
                    <div className={styles.selectContainer}>
                        <select
                            className={`${styles.inputField} ${styles.bikeChoice}`}
                            onChange={(event) => {
                                if (event.target.value === 'BIKE_ADD_NEW') {
                                    toggleNewBikeForm();
                                } else {
                                    setState({
                                        assignedBike: bikes.list.find((bike) => bike.name === event.target.value),
                                    });
                                }
                            }}
                            style={{ margin: 'auto', width: '100%' }}
                            value={assignedBike ? assignedBike.name : ''}
                        >
                            <TranslateOption disabled hidden value="">BIKE_ASSIGN</TranslateOption>
                            {bikes.list.map((bike) => (
                                <option key={bike.id} value={bike.name}>{bike.name}</option>
                            ))}
                            <TranslateOption key="BIKE_ADD_NEW" value="BIKE_ADD_NEW">
                                BIKE_ADD_NEW_DOTTED
                            </TranslateOption>
                        </select>
                    </div>
                </div>
                <div className={styles.buttonsContainer}>
                    <Button
                        inverse
                        className={styles.button}
                        color={AXS_TEXT_GREY}
                        onClick={() => {
                            onCancel();
                            setState({ assignedBike: null });
                        }}
                        type="button"
                    >
                        <Translate>CANCEL</Translate>
                    </Button>
                    <Button
                        className={styles.button}
                        onClick={() => assignBikeToActivity()}
                        type="button"
                    >
                        <Translate>SAVE</Translate>
                    </Button>
                </div>
            </>
        );
    }

    if (error) {
        return (
            <ErrorModal
                error={error}
                onClose={() => setState({ error: null })}
                onOverlayClick={() => {
                    setState({ error: null, showNewBikeForm: false });
                    onCancel();
                }}
            />
        );
    }

    const header = (bikes.list.length > 0) ? 'BIKE_ASSIGN' : 'NO_BIKES';

    return (
        <Modal
            className={`${showNewBikeForm ? styles.bikeFormContainer : ''}`}
            containerStyle={(showNewBikeForm
                ? { content: { maxHeight: '100vh', padding: 0 } }
                : { content: { minHeight: 'initial' } }
            )}
            contentClassName={styles.container}
            contentLabel="Assign Bike to Activity"
            contentStyle={showNewBikeForm ? { padding: 0, paddingBottom: '1rem' } : undefined}
            header={showNewBikeForm ? '' : header}
            hideCloseButton
            hideImage
            isOpen={open}
            onAfterOpen={() => fetchBike()}
            onClose={() => {
                setState({ assignedBike: null, showNewBikeForm: false });
                onCancel();
            }}
        >
            {renderAssignBikeForm()}
            <Spinner loading={isFetching || isUpdating} />
            {renderNewBikeForm()}
        </Modal>
    );
};

export default AssignBikeModal;
