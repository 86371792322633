import { scaleTime } from 'd3';
import {
    Area,
    Grid,
    Line,
    lineBisector,
    onMouseMove,
    onTouchMove,
    Scale,
    VerticalLine,
} from 'd3-charts-react';
import { useMemo } from 'react';
import { Axis, axisPropsFromTickScale, LEFT } from 'react-d3-axis';
import ReactResizeDetector from 'react-resize-detector';

import styles from './HeartRateLineChart.module.scss';

import LthrDisplay from '../thresholdDisplay/lthrDisplay';

import AltitudeChart from '../../altitudeChart';
import {
    categoriseData,
    extractTime,
    extractValue,
    formatHeartRate,
    mutateScaleNice,
} from '../../chartHelpers';

import CheckBox from '../../../../components/checkbox';
import Translate from '../../../../components/translate';
import {
    RED_LIGHT,
    SRAM_200,
    toLocaleString,
    ZONE_COLORS_HEART,
} from '../../../../constants';
import { useSetState } from '../../../../hooks';
import { useUnits } from '../../../../providers';
import ExpandChart from '../../../../views/expandChart';
import { ComponentSummary } from '../../../../providers';

interface ArealistProps {
    heartComponent: ComponentSummary,
    heartZones: {min: number, max: number}[],
    scale: any,
    showCategories:boolean,
}
function AreaList({
    heartComponent,
    heartZones,
    scale,
    showCategories,
}: ArealistProps): any {
    if (!showCategories) return null;

    const extractTimeCategorised = (dataPoint: any, value: number, index: number) => (
        extractTime(heartComponent, value, dataPoint.startIndex + index)
    );

    const categorisedData = useMemo(() => categoriseData(heartComponent.data.hr, heartZones) || [],
        [
            scale,
            heartComponent.data.hr,
            heartZones,
            showCategories,
        ]);

    return categorisedData.map((dataPoint) => (
        <Area
            data={dataPoint.data}
            key={dataPoint.startIndex}
            scale={scale}
            style={{
                fill: ZONE_COLORS_HEART[dataPoint.zoneIndex],
                stroke: ZONE_COLORS_HEART[dataPoint.zoneIndex],
            }}
            xExtractor={(value: number, index: number) => extractTimeCategorised(
                dataPoint,
                value,
                index,
            )}
            yExtractor={extractValue}
        />
    ));
}
interface HeartRateLineChartProps {
    disableEdit?: boolean,
    disableOwnerFeatures?: boolean,
    gpsComponent: ComponentSummary,
    heartComponent: ComponentSummary,
    heartZones: {min: number, max: number}[],
}

function HeartRateLineChart({
    disableEdit = false,
    disableOwnerFeatures = false,
    gpsComponent,
    heartComponent,
    heartZones,
}: HeartRateLineChartProps) {
    const [state, setState] = useSetState({
        altitude: 0,
        heartRate: 0,
        showCategories: !disableOwnerFeatures,
        timestamp: null,
    });
    const {
        altitude,
        heartRate,
        showCategories,
        timestamp,
    } = state;
    const units = useUnits();

    const heartRateExtractor = (...params: any) => {
        const values = lineBisector(...params);

        setState({ heartRate: values ? values[1] : 0 });
    };

    const renderChart = (scale: any) => {
        const xAxisProps = axisPropsFromTickScale(scale.x, 3);
        const yAxisProps = axisPropsFromTickScale(scale.y, 3);

        const width = scale.x.range()[1];
        const height = scale.y.range()[0];

        return (
            <div className={styles.chartContainer}>
                <svg
                    className={styles.chart}
                    height={height}
                    onMouseLeave={() => setState({ altitude: 0, heartRate: 0, timestamp: null })}
                    onMouseMove={(event) => {
                        const hoveredTimeStamp = onMouseMove(event, scale);

                        if (hoveredTimeStamp) {
                            setState({ timestamp: hoveredTimeStamp });
                        }
                    }}
                    onTouchMove={(event) => {
                        const hoveredTimeStamp = onTouchMove(event, scale);

                        if (hoveredTimeStamp) {
                            setState({ timestamp: hoveredTimeStamp });
                        }
                    }}
                    width={width}
                >
                    {!showCategories && (
                        <AltitudeChart
                            gpsComponent={gpsComponent}
                            height={height}
                            onAltitudeChange={(value) => setState({ altitude: value })}
                            sync={timestamp}
                            width={width}
                        />
                    )}
                    <Grid
                        hideXTicks
                        scale={scale}
                        yLineStyle={{ stroke: SRAM_200 }}
                        yTicks={yAxisProps.values}
                    />
                    <Line
                        className={styles.hiddenLine}
                        data={heartComponent.data.hr}
                        scale={scale}
                        style={!showCategories ? { stroke: RED_LIGHT } : null}
                        sync={timestamp}
                        valueExtractor={(...params: any) => heartRateExtractor(...params)}
                        xExtractor={(...params: any) => extractTime(heartComponent, ...params)}
                        yExtractor={extractValue}
                    />
                    <AreaList
                        heartComponent={heartComponent}
                        heartZones={heartZones}
                        scale={scale} showCategories={showCategories}
                    />
                    <VerticalLine scale={scale} sync={timestamp} />
                    {timestamp && (
                        <circle
                            className={styles.indicator}
                            cx={scale.x(timestamp)}
                            cy={Math.min(scale.y(heartRate), 400)}
                            r={7}
                        />
                    )}
                    <g className={styles.axis}>
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...yAxisProps}
                            format={formatHeartRate}
                            style={{ orient: LEFT }}
                        />
                    </g>
                    <g
                        className={styles.axis}
                        style={{ transform: `translateY(${height}px)` }}
                    >
                        <Axis
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...xAxisProps}
                            format={units.formatTime}
                        />
                    </g>
                </svg>
                <div className={styles.mobileTimeScale}>
                    <div className={styles.startTime}>
                        {units.formatTime(heartComponent.adjustedStartTs * 1000)}
                    </div>
                    <div className={styles.endTime}>
                        {units.formatTime(heartComponent.adjustedEndTs * 1000)}
                    </div>
                </div>
            </div>
        );
    };

    const renderScale = () => (
        <ReactResizeDetector handleWidth handleHeight>
            {({ width, height }) => (
                <Scale
                    height={height}
                    width={width}
                    xMax={heartComponent.adjustedEndTs * 1000}
                    xMin={heartComponent.adjustedStartTs * 1000}
                    xScale={scaleTime}
                    yMax={heartComponent.data.max_hr * 1.05}
                    yMin={Math.max((heartComponent.data.min_hr * 0.95), 0)}
                    yMutator={mutateScaleNice}
                >
                    {(scale: any) => renderChart(scale)}
                </Scale>
            )}
        </ReactResizeDetector>
    );

    if (!heartComponent) {
        return null;
    }

    if (!heartComponent.data.hr.length) {
        return (
            <div className={styles.noDataNote}>
                <Translate>NO_DATA_AVAILABLE_NOTES</Translate>
            </div>
        );
    }

    return (
        <ExpandChart title="HEART_RATE_CHART">
            <div className={styles.container}>
                <div className={styles.statisticsContainer}>
                    <div className={styles.statistic}>
                        {formatHeartRate(heartRate)}
                        &nbsp;
                        <Translate>UNITS_BPM</Translate>
                    </div>
                    {!showCategories && (
                        <div className={styles.statistic}>
                            {Number.isFinite(altitude) && (
                                <>
                                    {toLocaleString(altitude)}
                                    &nbsp;
                                    <Translate>{units.getLabelAltitude().shorthand}</Translate>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.yAxisLabel}>
                    <Translate>UNITS_BPM</Translate>
                </div>
                {renderScale()}
                <div className={styles.xAxisLabel}>
                    <Translate>TIME</Translate>
                </div>
                {!disableEdit && (
                    <div className={styles.footer}>
                        <LthrDisplay />
                    </div>
                )}
                {!disableOwnerFeatures && (
                    <div className={styles.buttonGroup}>
                        <CheckBox
                            checked={!showCategories}
                            label="ELEVATION"
                            onChange={() => setState({ showCategories: !showCategories })}
                            style={{ flexDirection: 'row-reverse', marginRight: '2rem' }}
                        />
                    </div>
                )}
            </div>
        </ExpandChart>
    );
}

export default HeartRateLineChart;
