export const AUTH_CONFIG = {
    audience: 'https://api-stage.quarqnet.com',
    clientID: 'YtRshLmw942Vrx5FkZlr1iFg2da6n005',
    domain: 'sramid-auth-stage.sram.com',
    redirectUri: 'https://axs-stage.sram.com/callback',
    responseType: 'token id_token',
    scope: 'openid email profile read:current_user update:current_user_identities',
};

export const AUTH0_LOGOUT_RETURN_TO_URL = 'https://axs-stage.sram.com';

export const COOKIE_DOMAIN = '.sram.com';

export const SERVER = 'stage';

export const SHARE_URL = 'https://axs-stage.sram.com/';

export const URI_QUARQNET_API = 'api-stage.quarqnet.com/api/v2/';

export const URL = 'https://nexus-stage.quarqnet.com/';
export const URL_API = `${URL}api/v2/`;
export const URL_BRODO = 'https://brodo-stage.quarqnet.com/';
export const URL_CLOUSEAU = 'https://bikes-stage.quarqnet.com/';
export const URL_CLOUSEAU_API = 'https://bikes-stage.quarqnet.com/api/';
export const URL_FIRMWARE_API = 'https://api.axs-stage.sram.com/firmware-service/';
export const URL_LOGGER = 'https://lincoln-stage.quarqnet.com/api/v1/axsweb';
export const URL_QUARQNET_API = `https://${URI_QUARQNET_API}`;
export const URL_QUARQTECH_API = 'https://nexus.stage.quarqtech.com/api/';
export const URL_STATIC_DATA = 'https://static.quarqnet.com/';

export const DEEP_LINK_PRODUCT_REGISTRATION = 'https://register-stage.quarqnet.com/';
export const DEEP_LINK_SRAM_COM = 'https://sram-stage.sram.com/login/emailverified';
export const DEEP_LINK_TRAILHEAD = 'https://thx-client-stage.quarqnet.com';
