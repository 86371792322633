import { Cookies } from 'react-cookie-consent';

import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useRef } from 'react';
import {
    Menu,
    MenuItem,
    Typeahead,
} from 'react-bootstrap-typeahead';
import { Helmet } from 'react-helmet';

import EagleChainLengthModal from '../eagleChainLengthModal';
import CustomBike from './customBike';
import styles from './MTBFlow.module.scss';

import Button from '../../../components/button';
import ContentContainer from '../../../components/contentContainer';
import MobileContainer from '../../../components/mobileContainer';
import Modal from '../../../components/modal';
import Spinner from '../../../components/spinner';
import Translate, { useTranslation } from '../../../components/translate';
import {
    AXS_TEXT_GREY, CHAINRING_SIZES, makeCancellable, RequestType,
    SRAM_100,
} from '../../../constants';
import { useSetState } from '../../../hooks';
import Logger from '../../../Logger';
import { useBikes } from '../../../providers/bikes';
import { useAuth } from '../../../providers/auth/AuthContext';
import FirebaseAnalytics from '../../../components/firebaseAnalytics';
import { checkCookieConsent } from '../../../components/analytics/Analytics';

import {
    bikeBrandType, bikeModelTypes, customValueType, frameTypes,
} from './types';
import ToggleSelector from '../../../components/toggleSelector/ToggleSelector';

interface MTBFlowProps {
    bikeModelBrands: bikeBrandType[] | null;
    setBikeBrands: (value: any) => void;
}

const DEFAULT_STATE = {
    categoryInfo: null,
    chainLength: null,
    chainRingOptions: CHAINRING_SIZES,
    chainStayLength: null,
    isCalculating: false,
    isFetching: false,
    selectedBikeBrand: null,
    selectedBikeFrame: null,
    selectedBikeModel: null,
    selectedBikeModelYear: null,
    selectedChainringSize: null,
    selectedFrameChainLengths: null,
    selectedFrameSize: null,
};

function MTBFlow({ bikeModelBrands, setBikeBrands }: MTBFlowProps) {
    const navigate = useNavigate();
    const { search } = useLocation();
    const { isAuthenticated } = useAuth();
    const cookieConsent = Cookies.get('CookieConsent');
    const [state, setState] = useSetState({
        anchorEl: null,
        bikeModelsList: null,
        linkagePosition: '',
        openChainLengthModal: false,
        openCustomBike: false,
        openDataUnavailableModal: false,
        openNoticeModal: false,
        openPopover: false,
        ...DEFAULT_STATE,
    });
    const bikes = useBikes();
    const translate = useTranslation();

    const {
        bikeModelList,
        categoryInfo,
        chainLength,
        chainRingOptions,
        chainStayLength,
        isCalculating,
        isFetching,
        linkagePosition,
        openChainLengthModal,
        openCustomBike,
        openDataUnavailableModal,
        openNoticeModal,
        selectedBikeFrame,
        selectedBikeBrand,
        selectedBikeModel,
        selectedBikeModelYear,
        selectedChainringSize,
        selectedFrameChainLengths,
        selectedFrameSize,
        supportedChainRings,
    } = state;

    const fetchBikeBrandsRequest = useRef<RequestType | null>(null);
    const fetchBikesByBrandRequest = useRef<RequestType | null>(null);
    const fetchChainLengthRequest = useRef<RequestType | null>(null);
    const fetchFrameCategoriesRequest = useRef<RequestType | null>(null);
    const fetchUdhRequest = useRef<RequestType | null>(null);

    const bikeBrandRef = React.createRef<any>();
    const bikeModelRef = React.createRef<any>();
    const frameSizeRef = React.createRef<any>();
    const chainringSizeRef = React.createRef<any>();

    function setSupportedChainRings() {
        if (!selectedFrameChainLengths || !selectedChainringSize) return;
        const selectedChainLength = selectedFrameChainLengths[selectedChainringSize.split('T')[0]];

        const supportedChainrings: Array<string> = [];
        Object.entries(selectedFrameChainLengths).forEach(([key, value]) => {
            if (value === selectedChainLength) {
                supportedChainrings.push(`${key}T`);
            }
        });

        setState({
            supportedChainRings: supportedChainrings,
        });
    }

    function setChainRingOptions(chainLengths: Record<string, unknown>) {
        const options = Object.keys(chainLengths).map((key) => ({ label: `${key}T`, value: key }));
        setState({ chainRingOptions: options });
    }

    async function eventLogger() {
        // Log events to GA and Logger
        const eventFields = {
            BikeModel: selectedBikeModel,
            Brand: selectedBikeBrand,
            ChainringSize: selectedChainringSize,
            FrameSize: selectedFrameSize,
        };

        const properties = {
            ...eventFields,
            action: 'Chainlength Calculation',
            category: 'Form Submission',
            label: 'Chainlength Calculated on Submit',
        };

        FirebaseAnalytics('Chainlength_Calculation', properties);

        if (window.woopra) {
            window.woopra.track('Chainlength_Calculation', properties);
        }

        Logger.log('ChainLengthFormFields', eventFields);
    }

    const resetSelectedFields = () => {
        setState({
            selectedBikeBrand: null,
            selectedBikeFrame: null,
            selectedBikeModel: null,
            selectedChainringSize: null,
            selectedFrameSize: null,
        });
    };

    async function fetchBikesByBrand(brandName: string) {
        const selectedBrand = bikeModelBrands?.find((brand: any) => brand.name === brandName);

        if (!selectedBrand) return null;
        fetchBikesByBrandRequest.current = makeCancellable(bikes.fetchBikesByBrandId(selectedBrand.id));

        try {
            const data = await fetchBikesByBrandRequest.current.promise;

            if (data) setState({ bikeModelList: data });

            fetchBikesByBrandRequest.current = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchBikesByBrandRequest.current = null;
            }

            return null;
        }
    }

    async function fetchBikeBrands() {
        setState({ isFetching: true });
        fetchBikeBrandsRequest.current = makeCancellable(bikes.fetchBikeModelBrands());

        try {
            const data = await fetchBikeBrandsRequest.current.promise;

            setBikeBrands(data);

            setState({ isFetching: false });

            fetchBikeBrandsRequest.current = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchBikeBrandsRequest.current = null;
                setState({ isFetching: false });
            }

            return null;
        }
    }

    async function fetchChainLengths(frame: frameTypes) {
        if (!frame) return;

        fetchChainLengthRequest.current = makeCancellable(bikes.fetchChainLengthsByFrame(frame.id));

        try {
            const data = await fetchChainLengthRequest.current.promise;

            fetchChainLengthRequest.current = null;

            if (data) {
                setChainRingOptions(data);
                setState({
                    selectedFrameChainLengths: data,
                });
            }

            if (!data) {
                setState({ openDataUnavailableModal: true });
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchChainLengthRequest.current = null;
            }
        }
    }

    function setChainInformation(frame: frameTypes) {
        if (!selectedFrameChainLengths || !selectedChainringSize) return;

        const selectedChainLength = selectedFrameChainLengths[selectedChainringSize.split('T')[0]];

        setState({
            chainLength: selectedChainLength,
            chainStayLength: frame.chainstay_length,
        });
    }

    async function fetchOEMFrameCategories(category: number) {
        if (!category) return;

        fetchFrameCategoriesRequest.current = makeCancellable(bikes.fetchOEMFrameCategories(category));

        try {
            const data = await fetchFrameCategoriesRequest.current.promise;

            fetchFrameCategoriesRequest.current = null;

            if (data && Object.keys(data).length) {
                setState({ categoryInfo: data, isCalculating: false, openChainLengthModal: true });

                if (checkCookieConsent(cookieConsent) || isAuthenticated()) eventLogger();
            }

            if (!data || !Object.keys(data).length) {
                setState({ isCalculating: false, openDataUnavailableModal: true });
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchFrameCategoriesRequest.current = null;
                setState({ isCalculating: false });
            }
        }
    }

    async function fetchUDH(frame: frameTypes) {
        if (!frame) return;

        fetchUdhRequest.current = makeCancellable(bikes.fetchUdhByFrame(frame.id));

        try {
            const data = await fetchUdhRequest.current.promise;

            fetchUdhRequest.current = null;

            if (data.length && data[0].category) {
                fetchOEMFrameCategories(data[0].category);
            }

            if (!data.length || !data[0].category) {
                setState({ openDataUnavailableModal: true });
                setState({ isCalculating: false });
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchUdhRequest.current = null;
                setState({ isCalculating: false });
            }
        }
    }

    function onBikeBrandChange(value: any) {
        if (!value[0]) {
            resetSelectedFields();
            return;
        }

        fetchBikesByBrand(value[0]);

        if (typeof value[0] === 'string') {
            setState({
                ...DEFAULT_STATE,
                selectedBikeBrand: value[0],
                selectedBikeModel: null,
                selectedBikeModelYear: null,
            });

            return;
        }

        setState({
            ...DEFAULT_STATE,
            openCustomBike: true,
            selectedBikeModelYear: null,
        });
    }

    function onBikeModelChange(value: Array<string | customValueType>) {
        if (!value[0]) {
            setState({ selectedBikeModel: null, selectedBikeModelYear: null });
            return;
        }
        if (typeof value[0] === 'string') {
            const lastDashIndex = value[0].lastIndexOf('-');
            const selectedBikeName = value[0].slice(0, lastDashIndex).trim();
            const selectedBikeYear = value[0].slice(lastDashIndex + 1).trim();

            const hasHighPivot = bikeModelList.find(
                (bike: bikeModelTypes) => bike.model_name === selectedBikeName && bike.high_pivot,
            );

            if (hasHighPivot) {
                setState({
                    openNoticeModal: true,
                    selectedChainringSize: null,
                    selectedFrameSize: null,
                });

                return;
            }

            setState({
                selectedBikeModel: selectedBikeName,
                selectedBikeModelYear: Number(selectedBikeYear),
                selectedChainringSize: null,
                selectedFrameSize: null,
            });

            return;
        }

        setState({
            openCustomBike: true,
            selectedFrameSize: null,
        });
    }

    function onChainringSizeChange(value: typeof CHAINRING_SIZES | string) {
        if (!value[0]) {
            setState({ selectedChainringSize: null });
            return;
        }
        setState({ selectedChainringSize: typeof value[0] === 'string' ? value[0] : value[0].label });
    }

    function getSelectedModel() {
        return (
            selectedFrameSize && bikeModelList && bikeModelList
                .find((bike: bikeModelTypes) => bike.brand.name === selectedBikeBrand
                    && bike.model_name === selectedBikeModel
                    && bike.year === selectedBikeModelYear
                    && bike.frames.find(({ frame_size }) => frame_size === selectedFrameSize))
        );
    }

    const selectedModel = useMemo(
        () => getSelectedModel(),
        [
            bikeModelList,
            selectedBikeBrand,
            selectedBikeModel,
            selectedBikeModelYear,
            selectedFrameSize,
            selectedBikeFrame,
        ],
    );

    function setSelectedBikeFrame() {
        const selectedBikeFrames = selectedModel && selectedModel.frames.filter(
            ({ frame_size }: { frame_size: string }) => frame_size === selectedFrameSize,
        );

        setState({ selectedBikeFrame: selectedBikeFrames && selectedBikeFrames.length && selectedBikeFrames[0] });

        if (selectedBikeFrames && selectedBikeFrames.length > 1) {
            setState({
                selectedBikeFrame: selectedBikeFrames.find(
                    ({ geometry_setting }: {geometry_setting: string}) => geometry_setting === linkagePosition,
                ),
            });
        }
    }

    function onFrameSizeChange(value: Array<customValueType>) {
        if (!value[0]) {
            setState({ selectedFrameSize: null });
            return;
        }

        if (typeof value[0] === 'string') {
            setState({ selectedFrameSize: value[0] });
            return;
        }

        setState({
            ...DEFAULT_STATE,
            openCustomBike: true,
            selectedFrameSize: value[0].label,
        });
    }

    function handleFormSubmit() {
        const isValidChainRingSize = chainRingOptions.map((sizes: Record<string, unknown>) => sizes.label)
            .includes(selectedChainringSize);

        if (!selectedModel || !isValidChainRingSize || !selectedBikeFrame) {
            setState({ openDataUnavailableModal: true });
            return;
        }
        setState({ isCalculating: true });
        fetchUDH(selectedBikeFrame);
    }

    const CloseBtn = ({ onClick }: {onClick: () => void}) => (
        <button
            type="button"
            onClick={onClick}
            style={{
                pointerEvents: 'auto',
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
            >
                <path
                    fill={AXS_TEXT_GREY}
                    d="M12 10.586l-5.293-5.293-1.414 1.414 5.293 5.293-5.293 5.293 1.414 1.414 5.293-5.293 5.293 5.293 1.414-1.414-5.293-5.293 5.293-5.293-1.414-1.414-5.293 5.293z"
                />
            </svg>
        </button>
    );

    function closeNoticeModal() {
        if (bikeModelRef.current) {
            bikeModelRef.current.clear();
        }
        setState({ selectedBikeModel: null, selectedBikeModelYear: null });
        setState({ openNoticeModal: false });
    }

    function renderContent() {
        if (isFetching) {
            return <Spinner />;
        }

        if (!bikeModelBrands || openCustomBike) {
            return null;
        }
        const bikeBrands = bikeModelBrands.map((bike: bikeBrandType) => bike.name);

        const bikeModels = (selectedBikeBrand && bikeModelList)
            && Array.from(new Set(bikeModelList.map((bike: bikeModelTypes) => `${bike.model_name} - ${bike.year}`)));

        const frameSizeList = (selectedBikeBrand && bikeModelList) && Array.from(new Set(bikeModelList
            .filter(
                (bike: bikeModelTypes) => bike.brand.name === selectedBikeBrand
                                && bike.model_name === selectedBikeModel
                                && bike.year === selectedBikeModelYear,
            )
            .map((model: bikeModelTypes) => model.frames)
            .flat()
            .map((frameSet: frameTypes) => frameSet.frame_size)
            .filter((frameSize: string) => frameSize)));

        const selectedBikeFrames = selectedModel && selectedModel.frames.filter(
            ({ frame_size }: { frame_size: string }) => frame_size === selectedFrameSize,
        );

        const positions = (selectedBikeFrames && selectedBikeFrames.length > 1)
            && selectedBikeFrames.filter((frames: frameTypes) => frames.geometry_setting)
                .map((frames: frameTypes) => frames.geometry_setting);

        if (positions && positions.length && !linkagePosition) {
            setState({ linkagePosition: positions[0] });
        }

        return (
            <ContentContainer className={styles.content}>
                <form
                    className={styles.options}
                    onSubmit={(event) => {
                        event.preventDefault();
                        handleFormSubmit();
                    }}
                >
                    {isCalculating && <Spinner />}
                    <div className={styles.row}>
                        <div className={styles.selector}>
                            <div className={styles.label}>
                                <Translate>BIKE_BRAND</Translate>
                            </div>
                            <Typeahead
                                id="bikeBrand"
                                onChange={(value) => onBikeBrandChange(value)}
                                options={bikeBrands}
                                placeholder={translate('NEW_BIKE_BRAND_PLACEHOLDER')}
                                ref={bikeBrandRef}
                                selected={selectedBikeBrand ? [selectedBikeBrand] : []}
                                renderMenu={(results, menuProps) => (
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    <Menu {...menuProps}>
                                        <button
                                            type="button"
                                            className={styles.menuItemNotListed}
                                            onClick={() => setState({ openCustomBike: true })}
                                        >
                                            <Translate>MY_BIKE_NOT_LISTED</Translate>
                                        </button>
                                        {results.map((result, index) => (
                                            <MenuItem option={result} position={index} key={result}>
                                                {result}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                )}
                            >
                                {({ onClear, selected }: {onClear: any, selected: any}) => (
                                    <div className="rbt-aux">
                                        {!!selected.length && <CloseBtn onClick={onClear} />}
                                    </div>
                                )}
                            </Typeahead>
                        </div>
                    </div>
                    {bikeModels && (
                        <div className={styles.row}>
                            <div className={styles.selector}>
                                <div className={styles.label}>
                                    <Translate>BIKE_MODEL</Translate>
                                </div>
                                <Typeahead
                                    id="bikeModel"
                                    onChange={(value) => onBikeModelChange(value)}
                                    options={bikeModels}
                                    placeholder={translate('NEW_BIKE_MODEL_PLACEHOLDER')}
                                    ref={bikeModelRef}
                                    selected={selectedBikeModel ? [selectedBikeModel] : []}
                                    renderMenu={(results, menuProps) => (
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        <Menu {...menuProps}>
                                            <button
                                                type="button"
                                                className={styles.menuItemNotListed}
                                                onClick={() => setState({ openCustomBike: true })}
                                            >
                                                <Translate>MY_BIKE_NOT_LISTED</Translate>
                                            </button>
                                            {results.map((result, index) => (
                                                <MenuItem option={result} position={index} key={result}>
                                                    {result}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    )}
                                >
                                    {({ onClear, selected }: {onClear: any, selected: any}) => (
                                        <div className="rbt-aux">
                                            {!!selected.length && <CloseBtn onClick={onClear} />}
                                        </div>
                                    )}
                                </Typeahead>
                            </div>
                        </div>
                    )}
                    {((frameSizeList && frameSizeList.length > 0) || selectedFrameSize) && (
                        <div className={styles.row}>
                            <div className={styles.selector}>
                                <div className={styles.label}>
                                    <Translate>FRAME_SIZE</Translate>
                                </div>
                                <Typeahead
                                    id="frameSize"
                                    onChange={(value) => onFrameSizeChange(value)}
                                    options={frameSizeList}
                                    placeholder={translate('NEW_FRAME_SIZE_PLACEHOLDER')}
                                    ref={frameSizeRef}
                                    selected={selectedFrameSize ? [selectedFrameSize] : []}
                                    renderMenu={(results, menuProps) => {
                                        if (results.length === 0) {
                                            return (
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                <Menu {...menuProps}>
                                                    <button
                                                        type="button"
                                                        className={styles.menuItemNotListed}
                                                        onClick={() => setState({ openCustomBike: true })}
                                                    >
                                                        <Translate>NOT_LISTED</Translate>
                                                    </button>
                                                </Menu>
                                            );
                                        }

                                        return (
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            <Menu {...menuProps}>
                                                <button
                                                    type="button"
                                                    className={styles.menuItemNotListed}
                                                    onClick={() => setState({ openCustomBike: true })}
                                                >
                                                    <Translate>NOT_LISTED</Translate>
                                                </button>
                                                {results.map((result, index) => (
                                                    <MenuItem option={result} position={index} key={result}>
                                                        {result}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        );
                                    }}
                                >
                                    {({ onClear, selected }: {onClear: any, selected: any}) => (
                                        <div className="rbt-aux">
                                            {!!selected.length && <CloseBtn onClick={onClear} />}
                                        </div>
                                    )}
                                </Typeahead>
                            </div>
                            {positions && positions.length && (
                                <div className={styles.selector}>
                                    <div className={styles.bikeModelInfo}>
                                        <Translate>BIKE_MODEL_LINKAGE_INFO</Translate>
                                    </div>
                                    <ToggleSelector
                                        activeButton={linkagePosition && positions.includes(linkagePosition)
                                            ? linkagePosition
                                            : ''}
                                        buttonClassName={styles.toggleButton}
                                        buttons={positions.map((position: any) => ({ label: position, value: position }))}
                                        color={SRAM_100}
                                        className={styles.toggler}
                                        onToggle={(value) => setState({ linkagePosition: value })}
                                    />

                                </div>
                            )}
                        </div>
                    )}
                    {((frameSizeList && frameSizeList.length > 0) || selectedChainringSize) && selectedModel && (
                        <div className={styles.row}>
                            <div className={styles.selector}>
                                <div className={styles.label}>
                                    <Translate>CHAINRING_SIZE</Translate>
                                </div>
                                <Typeahead
                                    id="chainringSize"
                                    onChange={(value) => onChainringSizeChange(value)}
                                    options={chainRingOptions}
                                    placeholder={translate('NEW_CHAINRING_SIZE_PLACEHOLDER')}
                                    ref={chainringSizeRef}
                                    selected={selectedChainringSize ? [selectedChainringSize] : []}
                                    renderMenu={(results, menuProps) => {
                                        if (results.length === 0) {
                                            return (
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                <Menu {...menuProps}>
                                                    <button
                                                        type="button"
                                                        className={styles.menuItemNotListed}
                                                        onClick={() => setState({ openCustomBike: true })}
                                                    >
                                                        <Translate>NOT_LISTED</Translate>
                                                    </button>
                                                </Menu>
                                            );
                                        }

                                        return (
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            <Menu {...menuProps}>
                                                <button
                                                    type="button"
                                                    className={styles.menuItemNotListed}
                                                    onClick={() => setState({ openCustomBike: true })}
                                                >
                                                    <Translate>NOT_LISTED</Translate>
                                                </button>
                                                {results.map((result, index) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <MenuItem option={result} position={index} key={index}>
                                                        {result.label}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        );
                                    }}
                                >
                                    {({ onClear, selected }: {onClear: any, selected: any}) => (
                                        <div className="rbt-aux">
                                            {!!selected.length && <CloseBtn onClick={onClear} />}
                                        </div>
                                    )}
                                </Typeahead>
                            </div>
                        </div>
                    )}
                    {selectedChainringSize && selectedBikeBrand && selectedBikeModel && selectedFrameSize && (
                        <div className={styles.footer}>
                            <button className={styles.nextButton} type="submit">
                                <Translate>SUBMIT</Translate>
                            </button>
                        </div>
                    )}
                </form>
            </ContentContainer>
        );
    }

    function renderSelectionUnvailableModal() {
        return (
            <Modal
                className={styles.noticeModalContainer}
                contentClassName={styles.infoContent}
                isOpen={openDataUnavailableModal}
                header="SELECTION_UNAVAILABLE"
                hideCloseButton
                hideImage
                contentLabel=""
            >
                <div className={styles.noticeContainer}>
                    <div>
                        <Translate>NO_DATA_CHAINLENGTH_CALCULATOR</Translate>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button
                            color={AXS_TEXT_GREY}
                            inverse
                            onClick={() => setState({ openDataUnavailableModal: false })}
                        >
                            <Translate>NO</Translate>
                        </Button>
                        <Button onClick={() => setState({ openCustomBike: true, openDataUnavailableModal: false })}>
                            <Translate>YES</Translate>
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }

    function renderIdlerPulleyNoticeModal() {
        return (
            <Modal
                contentLabel=""
                className={styles.noticeModalContainer}
                contentClassName={styles.infoContent}
                isOpen={openNoticeModal}
                header="Notice"
                hideCloseButton
                hideImage
            >
                <div className={styles.noticeContainer}>
                    <Translate>IDLER_PULLEY_NOTICE</Translate>
                    <div className={styles.buttonContainer}>
                        <a
                            className={styles.link}
                            // eslint-disable-next-line max-len
                            href="https://docs.sram.com/en-US/publications/5jblJ4SRpeHwjcuWG1vPy4#hashItem=idler-pulley-frame-setup-information"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <Button color={AXS_TEXT_GREY} inverse onClick={() => setState({ openNoticeModal: false })}>
                                <Translate>VIEW_PRODUCT_MANUAL</Translate>
                            </Button>
                        </a>
                        <Button onClick={() => closeNoticeModal()}>
                            <Translate>CLOSE</Translate>
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }

    useEffect(() => {
        if (!bikeModelBrands) fetchBikeBrands();

        return () => {
            if (fetchBikeBrandsRequest.current) {
                fetchBikeBrandsRequest.current.cancel();
                fetchBikeBrandsRequest.current = null;
            }

            if (fetchChainLengthRequest.current) {
                fetchChainLengthRequest.current.cancel();
                fetchChainLengthRequest.current = null;
            }
        };
    }, []);

    const checkUrlParams = () => {
        const queryParam = new URLSearchParams(search);
        const urlBikeBrand = queryParam.get('selectedBikeBrand');

        const bikeBrands = bikeModelBrands?.map((bike: bikeBrandType) => bike.name);
        if (!bikeBrands) return;

        if (urlBikeBrand && bikeBrands.includes(urlBikeBrand)) {
            onBikeBrandChange([queryParam.get('selectedBikeBrand')]);
        } else {
            navigate('/guides/chain/calculator');
            return;
        }

        setState({
            linkagePosition: queryParam.get('linkagePosition'),
            selectedBikeModel: queryParam.get('selectedBikeModel'),
            selectedBikeModelYear: queryParam.get('selectedBikeModelYear')
                ? Number(queryParam.get('selectedBikeModelYear'))
                : null,
            selectedChainringSize: queryParam.get('selectedChainringSize'),
            selectedFrameSize: queryParam.get('selectedFrameSize'),
        });
    };

    useEffect(() => {
        if (search && bikeModelBrands) checkUrlParams();
    }, [bikeModelBrands]);

    useEffect(() => {
        // When the user selects a new frame size set the selected bike frame
        setSelectedBikeFrame();
    }, [linkagePosition, selectedFrameSize, selectedModel]);

    useEffect(() => {
        // When the user selects a new frame we fetch the chainlengths and chainring options
        fetchChainLengths(selectedBikeFrame);
    }, [selectedBikeFrame]);

    useEffect(() => {
        // When the user selects a chainring size we calculate the chainlength
        // setSupportedChainRings(selectedChainringSize);
        setSupportedChainRings();
        setChainInformation(selectedBikeFrame);
    }, [selectedChainringSize, selectedFrameChainLengths]);

    return (
        <>
            <Helmet>
                <title>EAGLE AXS™ TRANSMISSION Chain and Setup Key Guide</title>
                <meta
                    name="description"
                    content="Calculate the perfect chain length for your
                    Eagle Transmission with the Eagle AXS™ Transmission Chain and Setup Key Guide."
                />
            </Helmet>
            <ContentContainer className={styles.optionsContainer}>
                {!openCustomBike && (
                    <MobileContainer className={styles.description}>
                        <Translate>CHAIN_LENGTH_DESCRIPTION</Translate>
                    </MobileContainer>
                )}
                {renderContent()}
                {renderSelectionUnvailableModal()}
                {renderIdlerPulleyNoticeModal()}
                <CustomBike
                    isOpen={openCustomBike}
                    onBack={() => setState({
                        openCustomBike: !openCustomBike,
                        ...DEFAULT_STATE,
                    })}
                />
               {openChainLengthModal && <EagleChainLengthModal
                    bike={`${selectedBikeBrand} ${selectedBikeModel}`}
                    chainLength={chainLength}
                    chainStayLength={chainStayLength}
                    // isCalculating={isCalculating}
                    isOpen={openChainLengthModal}
                    onClose={() => setState({ openChainLengthModal: !openChainLengthModal })}
                    setUpCogPosition={categoryInfo && categoryInfo.setup_cog_position}
                    setUpCogPositionText={categoryInfo && categoryInfo.setup_cog_position_text}
                    setUpCogImage={categoryInfo && categoryInfo.setup_cog_position_image}
                    setUpKeyPos={categoryInfo && categoryInfo.setup_key_position}
                    setUpKeyPosImage={categoryInfo && categoryInfo.setup_key_position_image}
                    supportedChainrings={supportedChainRings}
                />}
            </ContentContainer>
        </>
    );
}

export default MTBFlow;
