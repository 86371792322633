import jwtDecode from 'jwt-decode';
import { Component } from 'react';

import axios from 'axios';
import { node } from 'prop-types';

import AuthContext from './AuthContext';

import auth0WebAuth, { auth0ParseHash } from '../../auth0WebAuth';
import {
    AUTH0_LOGOUT_RETURN_TO_URL,
    AUTH_CONFIG,
    makeCancellable,
    URL,
    URL_API,
    URL_CLOUSEAU,
    URL_CLOUSEAU_API,
    URL_LOGGER,
    URL_QUARQNET_API,
    URL_STATIC_DATA,
} from '../../constants';
import Logger from '../../Logger';

// Used in multiple places to bypass email verified axios interceptor
const URL_ACTIVITIES = `${URL_QUARQNET_API}activities/`;
const URL_ADVANCED_UNITS = `${URL_API}advancedunits/`;
const URL_COMPONENT_SUMMARIES = `${URL_QUARQNET_API}componentsummaries/`;
const URL_RESEND_VERIFICATION_EMAIL = `${URL}confirm/resendverification/`;
const URL_WAFFLE = `${URL_API}users/self/flags/`;

class AuthProvider extends Component {
    static login() {
        auth0WebAuth.authorize({
            connections: ['sramid-db', 'apple', 'facebook', 'google-oauth2'],
            prompt: 'login',
        });
    }

    static async resendVerificationEmail(email) {
        try {
            await axios.get(
                URL_RESEND_VERIFICATION_EMAIL,
                { params: { email } },
            );

            return true;
        } catch (error) {
            Logger.error('Resend verification email error', error, email);

            return false;
        }
    }

    static async requestEmailChange(newEmail) {
        try {
            const { data } = await axios.post(
                `${URL_API}users/changeemail/`,
                { email: newEmail },
            );

            return data.msg;
        } catch (error) {
            Logger.warn('Request email change error', error, newEmail);
            throw error;
        }
    }

    constructor(props) {
        super(props);
        const accessToken = localStorage.getItem('accessToken');

        this.state = {
            accessToken,
            expiresAt: JSON.parse(localStorage.getItem('expiresAt')) || null,
            isFetchingAuth0Profile: false,
            isFetchingWaffleFlags: false,
            isLoggingIn: !accessToken,
            userProfile: null,
            userSessionError: null,
            waffleFlags: null,
        };

        this.fetchAccessTokenRequest = null;
        this.fetchAuth0ProfileRequest = null;
        this.handleServiceLinkedParseHashRequest = null;
        this.renewTokenRequest = null;

        // Add a request interceptor
        axios.interceptors.request.use((config) => this.axiosRequestInterceptor(config));
        // Add a response interceptor
        axios.interceptors.response.use(
            (config) => config,
            (error) => {
                if (error && error.response && error.response.status === 401) {
                    this.setState({ userSessionError: error });
                    return null;
                }
                throw error;
            },
        );
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.cancelAllRequests();
    }

    async handleAuthentication() {
        try {
            const authResult = await auth0ParseHash();

            await this.setSession(authResult);

            this.fetchData();
        } catch (error) {
            Logger.warn('Error logging in', error);
        }
    }

    async handleServiceLinked() {
        await this.fetchAuth0Profile();

        // Parse hash returned by auth0
        // Its important here not to override the currently logged in users state
        let authResult = null;
        try {
            authResult = await auth0ParseHash();
        } catch (error) {
            if (!error.isAuthenticated) {
                this.handleServiceLinkedParseHashRequest = null;

                sessionStorage.setItem(
                    'linkedServiceAuth0Error',
                    error.message,
                );

                Logger.warn('Error authenticating linking account', error);
            }

            return null;
        }

        return authResult;
    }

    async setSession(authResult) {
        // Set the time that the access token will expire at
        const expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();

        if (authResult.accessToken) {
            Logger.setUserId(jwtDecode(authResult.accessToken).sub);
        }

        await new Promise((resolve) => this.setState(
            {
                accessToken: authResult.accessToken,
                expiresAt,
                isLoggingIn: false,
            },
            () => resolve(authResult.accessToken),
        ));
    }

    async axiosRequestInterceptor(config) {
        // Allow logger requests even if there is no accesstoken or verified email
        // Allow resend the verification email requests if the email is not verified or not authenticated

        // Add public urls here
        switch (config.url) {
            case URL_ADVANCED_UNITS:
            case URL_LOGGER:
            case URL_RESEND_VERIFICATION_EMAIL:
                return config;
            default:
                if (
                    config.url.startsWith(URL_STATIC_DATA)
                    || config.url.startsWith(URL_CLOUSEAU_API)
                    || config.url.startsWith(URL_CLOUSEAU)
                ) {
                    return config;
                }

                break;
        }

        try {
            const accessToken = await this.fetchAccessToken();

            if (!accessToken
                && (config.url.startsWith(URL_ACTIVITIES) || config.url.startsWith(URL_COMPONENT_SUMMARIES))
            ) return config;

            if (!accessToken) throw new Error('No Access Token');

            const profile = await this.fetchAuth0Profile();

            if (!profile || !profile.email_verified) return null;

            await this.renewToken();
            // Ensure waffle is fetched before any other requests and block requests if under maintenance mode.
            if (config.url !== URL_WAFFLE) {
                await this.fetchWaffleJS();

                if (this.isFlagActive('maintenance')) return null;
            }

            // Declare accessToken here just incase the renewToken changed it.
            const { accessToken: updatedAccessToken } = this.state;
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${updatedAccessToken}`;

            return config;
        } catch (error) {
            this.setState({ userSessionError: error });
            Logger.warn('Error with network request', error, config);

            return null;
        }
    }

    cancelAllRequests() {
        if (this.fetchAccessTokenRequest) {
            this.fetchAccessTokenRequest.cancel();
        }

        if (this.fetchAuth0ProfileRequest) {
            this.fetchAuth0ProfileRequest.cancel();
        }

        if (this.handleServiceLinkedParseHashRequest) {
            this.handleServiceLinkedParseHashRequest.cancel();
        }

        if (this.renewTokenRequest) {
            this.renewTokenRequest.cancel();
        }
    }

    async fetchAccessToken() {
        const { accessToken } = this.state;

        if (accessToken) {
            return accessToken;
        }

        if (this.fetchAccessTokenRequest) {
            try {
                const authResult = await this.fetchAccessTokenRequest.promise;
                return authResult.accessToken;
            } catch (error) {
                return null;
            }
        }

        this.fetchAccessTokenRequest = makeCancellable(new Promise((resolve, reject) => (
            auth0WebAuth.checkSession(
                {},
                (error, authResult) => (error ? reject(error) : resolve(authResult)),
            )
        )));

        try {
            const authResult = await this.fetchAccessTokenRequest.promise;
            this.fetchAccessTokenRequest = null;

            await this.setSession(authResult);

            return authResult.accessToken;
        } catch (error) {
            this.fetchAccessTokenRequest = null;
            if (!error.isCancelled) {
                Logger.warn('Error Fetching Access Token', error);
                this.setState({ isLoggingIn: false });
            }

            return null;
        }
    }

    async fetchAuth0Profile() {
        const { userProfile } = this.state;

        if (userProfile) {
            return userProfile;
        }

        this.setState({ isFetchingAuth0Profile: true });

        try {
            await this.renewToken();
        } catch (error) {
            // Error logged in renew method
            this.setState({ isFetchingAuth0Profile: false });
            return null;
        }

        if (this.fetchAuth0ProfileRequest) {
            try {
                const fetchedAuth0UserProfile = await this.fetchAuth0ProfileRequest.promise;

                return fetchedAuth0UserProfile;
            } catch (error) {
                return null;
            }
        }

        // Declaration has to be after renewToken because the accessToken might be changed
        const { accessToken } = this.state;

        this.fetchAuth0ProfileRequest = makeCancellable(new Promise((resolve, reject) => {
            auth0WebAuth.client.userInfo(
                accessToken,
                (error, auth0UserProfile) => ((error) ? reject(error) : resolve(auth0UserProfile)),
            );
        }));

        try {
            const fetchedAuth0UserProfile = await this.fetchAuth0ProfileRequest.promise;

            this.setState(
                { isFetchingAuth0Profile: false, userProfile: fetchedAuth0UserProfile },
                () => {
                    this.fetchAuth0ProfileRequest = null;
                },
            );

            return fetchedAuth0UserProfile;
        } catch (error) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching auth0 user profile', error);

                this.fetchAuth0ProfileRequest = null;
                this.setState({ isFetchingAuth0Profile: false, userSessionError: error });

                if (error.statusCode === 401) {
                    Logger.warn('401 error');
                }
            }

            return null;
        }
    }

    async fetchData() {
        const accessToken = await this.fetchAccessToken();

        if (!accessToken) return;

        const profile = await this.fetchAuth0Profile();

        // Check if the account is verified before attempting any fetch commands
        if (!profile || !profile.email_verified) return;

        this.fetchWaffleJS();
    }

    async fetchWaffleJS() {
        const { waffleFlags } = this.state;

        if (waffleFlags) return waffleFlags;

        if (this.fetchWaffleJSRequest) {
            try {
                const { data } = await this.fetchWaffleJSRequest;

                return data;
            } catch (error) {
                return null;
            }
        }

        this.setState({ isFetchingWaffleFlags: true });

        this.fetchWaffleJSRequest = axios.get(URL_WAFFLE);

        try {
            const response = await this.fetchWaffleJSRequest;

            if (!response || !response.data) {
                this.setState({ isFetchingWaffleFlags: false });

                return null;
            }

            this.setState({ isFetchingWaffleFlags: false, waffleFlags: response.data });
            this.fetchWaffleJSRequest = null;

            return response.data;
        } catch (error) {
            Logger.warn('Error fetching WaffleJS', error);
            this.fetchWaffleJSRequest = null;
            this.setState({ isFetchingWaffleFlags: false });

            return null;
        }
    }

    isAuthenticated() {
        const { accessToken } = this.state;

        // Returns true if an access token exists and false if it doesn't
        return !!accessToken;
    }

    // Returns a boolean to check if the auth0 profile has its email verified
    isEmailVerified() {
        const { userProfile } = this.state;

        // Null for not sure yet
        if (!userProfile) {
            return null;
        }

        return userProfile.email_verified;
    }

    isFlagActive(flag) {
        const { waffleFlags } = this.state;

        if (!waffleFlags) return false;

        const waffleFlag = waffleFlags.find(({ name }) => name === flag);

        if (!waffleFlag) return false;

        return waffleFlag.active;
    }

    async linkService(service, redirect) {
        await this.renewToken();
        try {
            sessionStorage.setItem('linkedServiceAuth0Callback', service);

            if (redirect) {
                sessionStorage.setItem('linkedServiceAuth0CallbackRedirect', redirect);
            }

            const { accessToken, expiresAt } = this.state;

            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('expiresAt', expiresAt);
        } catch (error) {
            // try to catch errors using localstorage
            Logger.error('Error accessing browser storage', service, error);

            return null;
        }

        try {
            auth0WebAuth.authorize({
                connection: service,
                is_linking: true,
                responseType: 'token id_token',
                scope: 'openid email profile',
            });

            return true;
        } catch (error) {
            // try to catch errors from auth0 authorize
            Logger.error('Error linking account', service, error);

            return false;
        }
    }

    logout() {
        auth0WebAuth.logout({
            clientID: AUTH_CONFIG.clientID,
            returnTo: AUTH0_LOGOUT_RETURN_TO_URL,
        });

        // Clear access token and ID token from local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('profilePicture');
        localStorage.clear();
        sessionStorage.clear();

        this.setState({
            expiresAt: null,
            userProfile: null,
            userSessionError: null,
        });
    }

    // Renews the token and handles annoying snake / camel case difference
    async renewToken() {
        const { expiresAt } = this.state;

        // Checks if the current time is earlier than the expiration time
        if (!expiresAt || new Date().getTime() < expiresAt) {
            return;
        }

        if (this.renewTokenRequest) {
            await this.renewTokenRequest.promise;

            return;
        }

        this.renewTokenRequest = makeCancellable(new Promise((resolve, reject) => {
            // This is the single page application way to renew an auth0 token
            auth0WebAuth.checkSession(
                {},
                async (error, authResult) => {
                    if (error) {
                        Logger.warn('Error renewing token', error);

                        this.setState({ userSessionError: error });

                        localStorage.clear();

                        reject(error);
                        return;
                    }

                    if (!authResult || !authResult.accessToken || !authResult.idToken) {
                        const authError = new Error('Unsatifactory data in response');
                        Logger.warn('Error renewing token', authError);

                        this.setState({ userSessionError: authError });

                        localStorage.clear();

                        reject(error);
                        return;
                    }

                    await this.setSession(authResult);
                    resolve();
                },
            );
        }));

        try {
            await this.renewTokenRequest.promise;
            this.renewTokenRequest = null;
        } catch (error) {
            if (!error.isCancelled) {
                Logger.warn('Error renewing token', error);
                this.renewTokenRequest = null;
            }

            throw error;
        }
    }

    async requestAccountDelete() {
        const { userProfile } = this.state;

        try {
            await axios.delete(`${URL_API}users/${userProfile.sub}/`);

            return true;
        } catch (error) {
            Logger.warn('Request delete account error', error);
            throw error;
        }
    }

    async requestPasswordChange() {
        const { userProfile } = this.state;

        const passwordRequest = new Promise((resolve, reject) => {
            auth0WebAuth.changePassword(
                { connection: 'sramid-db', email: userProfile.email },
                (error, res) => (error ? reject(error) : resolve(res)),
            );
        });

        try {
            const res = await passwordRequest;
            return res;
        } catch (error) {
            Logger.warn('Error requesting password change', error, userProfile.email);

            throw error;
        }
    }

    render() {
        const { children } = this.props;

        return (
            <AuthContext.Provider
                value={{
                    auth: {
                        ...this.state,
                        fetchAccessToken: () => this.fetchAccessToken(),
                        fetchAuth0Profile: () => this.fetchAuth0Profile(),
                        fetchWaffleJS: () => this.fetchWaffleJS(),
                        handleAuthentication: () => this.handleAuthentication(),
                        handleServiceLinked: (...params) => this.handleServiceLinked(...params),
                        isAuthenticated: () => this.isAuthenticated(),
                        isEmailVerified: () => this.isEmailVerified(),
                        isFlagActive: (...params) => this.isFlagActive(...params),
                        linkService: (...params) => this.linkService(...params),
                        login: AuthProvider.login,
                        logout: () => this.logout(),
                        requestAccountDelete: () => this.requestAccountDelete(),
                        requestEmailChange: AuthProvider.requestEmailChange,
                        requestPasswordChange: () => this.requestPasswordChange(),
                        resendVerificationEmail: AuthProvider.resendVerificationEmail,
                    },
                }}
            >
                {children}
            </AuthContext.Provider>
        );
    }
}

AuthProvider.defaultProps = {
    children: null,
};

AuthProvider.propTypes = {
    children: node,
};

export default AuthProvider;
